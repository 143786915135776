import React, { useState, useRef } from 'react';
import { Card } from "primereact/card";
import { useTranslation } from 'react-i18next';

export default function InfoCard() {
  const {t} = useTranslation();

  return (
    <div>
      <div className="mt-3">
        <Card title={t("cardtitle")} className='text-center border-round-xl m-auto p-1' style={{ width: '25rem', marginBottom: '2em' }}>
          <p className="m-0" style={{lineHeight: '1.5'}}>{t("cardtext")}
          </p>
        </Card>
      </div>
    </div>
  )
}